import Styled from 'vue3-styled-components';

const ProductCard = Styled.div`
border-radius: 10px;
background-color: #fff;
position: relative;

@media only screen and (max-width: 767px){
		max-width: 350px;
		margin: 0 auto;
}
&.list-view{
		max-width: 100%;
		.product-single-price__offer{
				@media only screen and (max-width: 991px) and (min-width: 768px){
						display: block;
				}
		}
}
.product-list{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		figure{
				${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 15px;
				@media only screen and (max-width: 1199px){
						${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 0;
				}
				@media only screen and (max-width: 991px){
						margin: 0 0 20px;
				}
				img{
						border-radius: 10px;
				}
		}
		.product-single-description{
				p{
						color: ${({ theme }) => theme['gray-color']};
						font-size: 15px;
				}
		}
		.product-single-title{
				font-size: 18px;
				margin: 25px 0 16px;
				@media only screen and (max-width: 1199px){
						margin: 0 0 16px;
				}
		}
		.product-single-info{
				margin-top: 25px;
				@media only screen and (max-width: 1199px){
						margin-top: 0;
				}
		}
		.product-single-price__new{
				font-size: 16px;
		}
		.product-single-action{
				flex-flow: column;
				align-items: flex-start;
				margin: 28px 0 0 0;
			
				.btn-cart{
						margin: 0 0 10px;
				}
				.ant-btn-sm{
						height: 38px;
				}
		}
		.btn-heart{
				@media only screen and (max-width: 1599px){
						top: 0;
						${({ theme }) => (theme.rtl ? 'left' : 'right')}: 10px;
				}
				@media only screen and (max-width: 1199px){
						top: -4px;
						${({ theme }) => (theme.rtl ? 'left' : 'right')}: 0;
				}
				@media only screen and (max-width: 991){
						top: 0;
				}
		}
}
figure{
		margin-bottom: 0;
		img{
				width: 100%;
		}
}
figcaption{
		padding: 20px 20px 26px;
}
.btn-heart{
		position: absolute;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 34px;
		height: 34px;
		${({ theme }) => (theme.rtl ? 'left' : 'right')}: 20px;
		top: 15px;
		background-color: #fff;
		border-radius: 50%;
		box-shadow: 0 5px 10px ${({ theme }) => theme['light-color']}15;
}
.product-single-title{
		margin-bottom: 10px;
		font-size: 15px;
		font-weight: 500;
		a{
				color: ${({ theme }) => theme['dark-color']};
				&:hover{
						color: ${({ theme }) => theme['primary-color']};
				}
		}
}
.product-single-price{
		margin-bottom: 5px;
		del{
				margin: 0 5px;
		}
}
.product-single-price__new{
		font-weight: 600;
		color: ${({ theme }) => theme['primary-color']};
}
.product-single-price__offer{
		font-weight: 500;
		font-size: 12px;
		color: ${({ theme }) => theme['secondary-color']};
}
.product-single-rating{
		font-size: 12px;
		font-weight: 500;
		//display: flex;
		//flex-wrap: wrap;
		//align-items: center;
		.ant-rate{
				line-height: 1.2;
				${({ theme }) => (theme.rtl ? 'margin-left' : 'margin-right')}: 5px;
		}
		.ant-rate-star:not(:last-child) {
				${({ theme }) => (!theme.rtl ? 'margin-right' : 'margin-left')}: 2px !important;
		}
		.ant-rate-star{
				div{
						transform: none !important;
				}
		}
		.total-reviews{
				font-weight: 400;
				${({ theme }) => (!theme.rtl ? 'margin-left' : 'margin-right')}: 6px;
				color: ${({ theme }) => theme['light-color']};
		}
		svg{
				width: 13px;
		}
}
.product-single-action{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 20px -5px -5px -5px;
	
		.ant-btn-default{
				border-color: ${({ theme }) => theme['border-color-normal']};
				&:hover{
						border-color: ${({ theme }) => theme['primary-color']};
				}
		}
		.ant-btn-white{
				&:hover{
						border-color: ${({ theme }) => theme['primary-color']} !important;
				}
		}
		.ant-btn-sm{
				font-size: 12px;
				padding: 0px 18.065px;
				height: 36px;
		}
}
`;

export { ProductCard };
