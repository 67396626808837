<template>
  <ProductCard class="list-view" :style="{ marginBottom: '20px' }">
    <div class="product-list">
      <a-row :gutter="15">
        <a-col :md="6" :xs="24">
          <figure>
            <img
              :style="{ width: '100%' }"
              :src="renderData.attributes.preview_url ?? require(`@/static/img/banner/slider-2.png`)"
              alt=""
            />
          </figure>
        </a-col>
        <a-col :md="12" :xs="24">
          <div class="product-single-description">
            <div>
              <sdHeading class="product-single-title" as="h5">
                <p :to="`/contest/${renderData.id}`">
                  {{ renderData.attributes.title }}
                </p>
                <Status
                  :status="renderData.attributes.status"
                  :text="i18n.t('contests.statuses.' + renderData.attributes.status)"
                />
              </sdHeading>
              <!--              <p class="contest__dates" v-if="!renderData.attributes.perpetual">-->
              <!--                {{ i18n.t('contests.page.from') }} {{ i18n.d(renderData.attributes.start_at, 'contest') }}-->
              <!--                {{ i18n.t('contests.page.to') }} {{ i18n.d(renderData.attributes.end_at, 'contest') }}-->
              <!--              </p>-->
            </div>
            <div class="contests__description" v-html="renderData.attributes.description" />
            <div class="contests__footer">
              <router-link
                :to="`/contest/${renderData.id}`"
                v-if="typeof renderData.attributes.participants_count == 'number'"
              >
                <a-tag color="blue">
                  {{
                    i18n.t('contests.page.participants', {
                      participants: renderData.attributes.participants_count,
                    })
                  }}
                </a-tag>
              </router-link>
              <a-tag color="blue" v-if="typeof renderData.attributes.attempts_count == 'number'">
                {{ i18n.t('contests.page.attempts_count', { attempts: '∞' }) }}
                <!--{{
                  i18n.t('contests.page.attempts', {
                    attempts: renderData.attributes.attempts_count,
                    total: renderData.attributes.total_attempts_count,
                  })
                }}-->
              </a-tag>
              <a-tag color="cyan">
                {{ i18n.t('contests.page.duration', renderData.attributes.perpetual ? 1 : 2) }}
                {{ renderData.attributes.duration }} {{ i18n.t('contests.page.days') }}
              </a-tag>
              <!--<a-tag color="green"> </a-tag>-->
            </div>
          </div>
        </a-col>
        <a-col :md="6" :xs="24">
          <div class="product-single-info">
            <p class="product-single-price">
              <span class="product-single-price__new">
                {{ i18n.t('contests.page.amount') }}: {{ i18n.n(+renderData.attributes.amount, 'decimal') }}
                {{ renderData.attributes.amount_currency }}
              </span>
              <br />
              <span class="product-single-price__new">
                {{ i18n.t('contests.page.leverage') }}: 1:{{ renderData.attributes.leverage }}
              </span>
            </p>
            <div class="product-single-action" v-if="renderData.attributes.status != 'closed'">
              <sdButton size="large" type="primary" @click="changeModal" v-if="renderData.attributes.attempts_count">
                {{ i18n.t('contests.page.participate') }}
              </sdButton>
              <sdButton size="large" type="primary" @click="buyTrait" v-else>
                {{ i18n.t('contests.page.buy_trait') }}
              </sdButton>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </ProductCard>
</template>
<script>
import PropTypes from 'vue-types';
import { ProductCard } from '../Style';
import { computed, defineAsyncComponent, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const Status = defineAsyncComponent(() => import('../../../components/elements/Status.vue'));

const ProductCardsList = {
  name: 'ProductCards',
  components: { ProductCard, Status },
  props: {
    product: PropTypes.object.def({}),
    contest: {
      type: Object,
      default: {},
      required: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const { contest } = toRefs(props);

    const renderData = computed(() => contest.value);
    const { matched } = useRoute();

    const changeModal = () => {
      emit('changeModal', contest.value);
    };

    const buyTrait = () => {
      emit('update:traitModalVisible', true);
      emit('update:changeContest', contest.value);
    };

    return { i18n, renderData, matched, changeModal, buyTrait };
  },
};

export default ProductCardsList;
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
.contest {
  &__title {
    font-weight: 600;
  }
  &__dates {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
.product-single-description {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.product-single-title {
  margin: 10px 0 !important;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    p {
      margin-right: 20px;
    }
    p:nth-last-child(1) {
      white-space: nowrap;
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.contests__description {
  margin: 20px 0;
}
.product-single-info {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 10px !important;
  .product-single-action {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 500px) {
    flex-direction: row;
    margin-top: 20px !important;
  }
}
.contests__footer {
  @media screen and (max-width: 768px) {
    span {
      margin-top: 10px;
    }
  }
}
</style>
